import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { colors } from './../../../../../defaults/styles';

// == STYLING == //
const LangSwitcher = styled.div`
  color: ${colors.white.normal};
`;

const LangItem = styled.span`
  cursor: ${props => (props.selected ? 'default' : 'pointer')};
  display: inline-block;
  font-weight: ${props => (!props.selected ? 'inherit' : 'bold')};

  transition: color ease-in-out 0.1s;

  &:hover {
    color: ${props =>
      props.selected ? colors.white.normal : colors.primary.normal};
  }

`;
// == /STYLING == //

const LanguageSelector = ({
  activeLanguage,
  setActiveLanguage,
  history,
  location,
  isMobile,
}) => {

  const switchLanguage = useCallback(
    (lang) => {
      if (activeLanguage?.code !== lang) {
        const updatedUrl = location.pathname.replace(
          /^\/(en|fr)/,
          `/${lang}`
        );
        localStorage.setItem('locale', lang);
        setActiveLanguage(lang);
        history.push(updatedUrl);
      }
    },
    [activeLanguage, setActiveLanguage, history, location.pathname]
  );

  useEffect(() => {
    const langFromUrl = location.pathname.split('/')[1];
    if (['en', 'fr'].includes(langFromUrl) && activeLanguage?.code !== langFromUrl) {
      localStorage.setItem('locale', langFromUrl);
      setActiveLanguage(langFromUrl);
    }
  }, [location.pathname, activeLanguage, setActiveLanguage]);
  
  return (
  <LangSwitcher>
    <LangItem 
      isMobile={isMobile}
      selected={activeLanguage && activeLanguage.code === 'en'}
      onClick={() => { switchLanguage('en'); }}
    >
      EN
    </LangItem>
    ｜
    <LangItem
      selected={activeLanguage && activeLanguage.code === 'fr'}
      onClick={() => { switchLanguage('fr'); }}
    >
      FR
    </LangItem>
  </LangSwitcher>);
}

export default withRouter(withLocalize(LanguageSelector));
