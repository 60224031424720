import React, { useContext, useMemo, useCallback } from 'react';
import UIMain from '../../interface/UIMain';
import UIContainer from '../../interface/UIContainer';
import { spacing, colors } from '../../../../defaults/styles';

import { withLocalize } from 'react-localize-redux';
import { LoggedInHomePageProductSlideShow } from '../LoggedInHomePage/LoggedInHomePageProductSlideShow';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { GridLoader } from 'react-spinners';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

const LoaderWrapper = styled.div`
padding: ${spacing.veryLarge}px;
display: flex;
justify-content: center;
align-items: center;
height: 80vh;
width: 100%;
`;

const LoadingGrid = (
  <LoaderWrapper>
    <GridLoader css={{ margin: 'auto' }} color={colors.main.normal} size={12} />
  </LoaderWrapper>
);

export const HomePagePresentation = withLocalize(
  ({ featureProducts, translate, history, activeLanguage, apiCallsInProgress }) => {
    const { isMobile, isTablet } = useContext(ResizeAwareContext);
    const t = (tag) => `loggedInHome.${tag}`;
    const lang = useMemo(
      () => (activeLanguage?.code || 'en'),
      [activeLanguage]
    );

    const goToListing = useCallback(
      (e, display, displayId = undefined) => {
        e.preventDefault();
        history.push({
          pathname: `/${lang}/catalog`,
          search:
            `?display=${display}` +
            (displayId ? `&displayId=${displayId}` : ''),
        });
      },
      [lang, history]
    );

    if (
      featureProducts.newProducts ||
      featureProducts.bestSellers ||
      featureProducts.canadianProducts
    ) {
      const { newProducts, bestSellers, canadianProducts } = featureProducts;

      return (
        <UIContainer isMobile={isMobile} isTablet={isTablet}>
          {newProducts.length > 0 && (
            <LoggedInHomePageProductSlideShow
              isMobile={isMobile}
              isTablet={isTablet}
              list={newProducts}
              title={translate(t('new.title'))}
              counterLabel_one={translate(t('new.counterLabel_one'))}
              counterLabel_other={translate(
                t('new.counterLabel_other')
              )}
              buttonLabel={translate(t('new.button'))}
              addLinkLabel={translate(t('new.addLinkLabel'))}
              url={'/catalog?display=NEW'}
              onClick={event => goToListing(event, 'NEW')}
              apiCallsInProgress={apiCallsInProgress}
              slideHeightCorrection={true}
            />
          )}
          {bestSellers.length > 0 && (
            <LoggedInHomePageProductSlideShow
              isMobile={isMobile}
              isTablet={isTablet}
              list={bestSellers}
              title={translate(t('hot.title'))}
              counterLabel_one={translate(t('hot.counterLabel_one'))}
              counterLabel_other={translate(
                t('hot.counterLabel_other')
              )}
              buttonLabel={translate(t('hot.button'))}
              addLinkLabel={translate(t('hot.addLinkLabel'))}
              url={'/catalog?display=BEST_SELLER'}
              onClick={event => goToListing(event, 'BEST_SELLER')}
              apiCallsInProgress={apiCallsInProgress}
              slideHeightCorrection={true}
            />
          )}
          {canadianProducts && canadianProducts.length > 0 && (
            <LoggedInHomePageProductSlideShow
              isMobile={isMobile}
              isTablet={isTablet}
              list={canadianProducts}
              title={translate(t('canadian.title'))}
              counterLabel_one={translate(t('canadian.counterLabel_one'))}
              counterLabel_other={translate(
                t('canadian.counterLabel_other')
              )}
              buttonLabel={translate(t('canadian.button'))}
              addLinkLabel={translate(t('canadian.addLinkLabel'))}
              url={'/catalog?display=CANADIAN'}
              onClick={event => goToListing(event, 'CANADIAN')}
              apiCallsInProgress={apiCallsInProgress}
              slideHeightCorrection={true}
            />
          )}
        </UIContainer>
      );
    } else {
      return (
        <UIMain isMobile={isMobile} isTablet={isTablet}>
          {LoadingGrid}
        </UIMain>
      );
    }
  }
);