import React from 'react';
import Item from './Item';
import { Translate, withLocalize } from 'react-localize-redux';
import content from '../../../../content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxOpen as openOrdersIcon,
  faTruck as completedOrdersIcon,
  faFileAlt as reportsIcon,
  faSignOutAlt as logOutIcon,
  faFileDownload as downloadCatalogueIcon,
  faTasks as shoppingListIcon,
  faPencil as myPresentationIcon,
} from '@fortawesome/pro-solid-svg-icons';
import { faCog as settingsIcon } from '@fortawesome/pro-regular-svg-icons';
import { lsStyled as styled } from '../../../../../../../../../tools/helpers/lsStyled';
import ShoppingListPopup from '../../../../../../../pages/CatalogPage/ShoppingList/ShoppingListPopup';
import { ShoppingListContainer } from '../../../../../../../../smart/ShoppingList';
import { useDispatch, useSelector } from 'react-redux';
import { toggleShoppingList } from '../../../../../../../../../redux/actions/togglePopupActions';

const icons = {
  openOrdersIcon,
  completedOrdersIcon,
  reportsIcon,
  settingsIcon,
  logOutIcon,
  downloadCatalogueIcon,
  shoppingListIcon,
  myPresentationIcon,
};

const UserMenu = ({ activeLanguage, isMobile }) => {
  const dispatch = useDispatch();
  const showPopup = useSelector((state) => state.togglePopups.showShoppingListPopup);

  const t = (tag) => `header.menu.${tag}`;
  const lang = activeLanguage?.code || 'en';

  const handleShoppingListClick = (event) => {
    event.preventDefault();
    dispatch(toggleShoppingList(true));
  };

  return (
    <>
      <nav
        style={{
          position: 'absolute',
          backgroundColor: 'white',
          right: 0,
          boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
          minWidth: lang !== 'en' ? 225 : 185,
          zIndex: 12,
        }}
      >
        {content.userLinks.map(
          ({ url = '/', lexiconCode, callback = () => { } }, index) => (
            <Item key={'item-user-' + index} to={url} 
              onClick={
                lexiconCode === 'shoppingList' ? handleShoppingListClick : callback
              }
            >
              <IconBox>
                <FontAwesomeIcon icon={icons[`${lexiconCode}Icon`]} />
              </IconBox>
              <Translate id={t(lexiconCode)} />
            </Item>
          )
        )}
      </nav>
      {showPopup && (
        <ShoppingListContainer>
          <ShoppingListPopup
            showPopup={showPopup}
            setShowPopup={(isOpen) => dispatch(toggleShoppingList(isOpen))}
            isMobile={isMobile}
          />
        </ShoppingListContainer>
      )}
    </>
  );
};

export default withLocalize(UserMenu);

const IconBox = styled.span`
  display: inline-block;
  text-align: center
  width: 2em;
`;
