import React, { useContext } from 'react';
import UIMain from './../../interface/UIMain';
import PartnersAndSuppliers from './PartnersAndSuppliers';
import RewardNetwork from './RewardNetwork';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { withLocalize } from 'react-localize-redux';
import { Helmet } from 'react-helmet-async';

const BrandPartnersPage = ({ translate }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <Helmet>
        <title>{translate('footer.services.brandPartners')}</title>
        <meta
          name="description"
          content={translate('footer.services.partnersAndSuppliers.mainParagraph')}
        />
        <meta
          name="keywords"
          content="Loyalty Source, Rewards, Marketplace, Catalogue, Benefits, Distribution Center, form"
        />
        <meta name="author" content="Loyalty Source" />
      </Helmet>
      <PartnersAndSuppliers isMobile={isMobile} isTablet={isTablet} />
      <RewardNetwork isMobile={isMobile} isTablet={isTablet} />
    </UIMain>
  );
};

export default withLocalize(BrandPartnersPage);