import { TOGGLE_USER_MENU, TOGGLE_SHOPPING_LIST } from "./types";

export const toggleMenu = (isOpen) => ({
  type: TOGGLE_USER_MENU,
  payload: isOpen,
});

export const toggleShoppingList = (isOpen) => ({
    type: TOGGLE_SHOPPING_LIST,
    payload: isOpen,
  });
