import * as types from './types'
import { brand } from '../../tools/api'
import { beginApiCall, apiCallError } from './apiStatusActions'

export const loadBrandsSuccess = brands => {
  return {
    type: types.LOAD_BRANDS_SUCCESS,
    brands
  }
}

export const loadBrandCountSuccess = brandCount => {
  return {
    type: types.LOAD_BRAND_COUNT_SUCCESS,
    brandCount
  }
}

export const loadBrands = (language) => {
  return dispatch => {
    dispatch(beginApiCall())
    return brand
      .getBrands(language)
      .then(({ json: brands }) => {
        dispatch(loadBrandsSuccess(Object.entries(brands)))
      })
      .catch(error => {
        dispatch(apiCallError(error))
        throw error
      })
  }
}

export const loadBrandCount = () => {
  return dispatch => {
    dispatch(beginApiCall())
    return brand
      .getBrandCount()
      .then(({ json: count }) => {
        dispatch(loadBrandCountSuccess(count));
      })
      .catch(
        error => {
          dispatch(apiCallError(error));
          dispatch(loadBrandCountSuccess(380));
        }
      )
  }
}