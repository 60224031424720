import React from 'react';
import Currency from 'react-currency-formatter';
import { withLocalize, Translate } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import { colors } from '../../../ui/common';

const Table = styled.table`
    margin-top: 1rem;
    border-spacing: 0px;
    border-collapse: collapse;
    width: 100%;
`;

const TableRow = styled.tr`
    width: 100%;
`;

const TableColumn = styled.td`
    line-height: 1.2;
    opacity: 0.8;
    vertical-align: top;
    padding: ${props => !props.lastChild ? '0 0.5rem 0.75rem 0' : '0 0 0.75rem 0.5rem'};
    text-align: ${props => !props.lastChild ? 'left' : 'right'};
    white-space: ${props => !props.lastChild ? '' : 'pre'};
    ${props => props.footer ? `
        border-top: 1px solid ${colors.gray.light};
        font-weight: 700;
        opacity: 1;
        padding: 0.75rem 0 0;
        white-space: pre;
    ` : ''};
`;

const CartSectionTotal = ({ activeLanguage, translate, items, isMobile }) => {
    const lang =
        activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

    let total = 0;
    if (items) {
        items.forEach(item => {
            total += (Number(item.price) * Number(item.quantity));
        });
    }

    return (
        <>
            <h2>{translate('checkout.summary.title')}</h2>
            <Table>
                <tbody>
                    {items && items
                                    .filter(({statusId})=> statusId !== 4)
                                    .map(({ quantity, name, price }, index) => 
                        <TableRow
                            key={`order-summary-product-${index}`}
                        >
                            <TableColumn><b>{Number(quantity).toLocaleString(lang)}</b></TableColumn>
                            <TableColumn><FontAwesomeIcon style={{ fontSize: '0.8rem' }} icon={faTimes} /></TableColumn>
                            <TableColumn>{name}</TableColumn>
                            <TableColumn lastChild>
                                {/* {Number(price).toLocaleString(locale, {minimumFractionDigits: 2})} */}
                                <Currency quantity={Number(price)} currency="CAD" locale={lang} />
                            </TableColumn>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableColumn colSpan={3} footer={true}>
                            <Translate id="checkout.summary.total" />
                        </TableColumn>
                        <TableColumn footer={true} lastChild>
                            {/* {Number(total).toLocaleString(locale, {minimumFractionDigits: 2})} */}
                             <Currency quantity={total} currency="CAD" locale={lang} />
                        </TableColumn>
                    </TableRow>
                </tbody>
            </Table>
        </>
    );
}

export default withLocalize(CartSectionTotal);