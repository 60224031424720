import React, { useEffect, useState } from 'react';
import Title from './Title';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { lsFormatNum as formatNum } from '../../../../tools/helpers/lsFormatNum';
import { colors, fonts, spacing } from '../../../../defaults/styles';
import { withLocalize } from 'react-localize-redux';
import { loadBrands } from '../../../../redux/actions/brandActions';
import { connect } from 'react-redux';
import { convert, fixHtmlEncodings } from '../../../../tools/helpers/descriptionConverter';

const Breadcrumbs = styled.p`
  color: ${colors.gray.normal};
  font-size: ${fonts.sizes.smallText}px;
  margin-bottom: ${spacing.small}px;
`;

const Bread = styled.span`
  color: ${props =>
    props.isLast || props.isFirst ? colors.black.normal : null};
  font-weight: ${props =>
    props.isLast || props.isFirst ? fonts.weights.heading : null};
  &::after {
    content: "${props => (!props.isLast ? ' > ' : null)}";
    display: inline-block;
    padding-right: ${spacing.tiny}px;
    padding-left: ${spacing.tiny}px;
  }
  ${props => (props.isFirst ? 'text-decoration: underline;' : '')}
  ${props => (props.isFirst ? 'cursor: pointer;' : '')}
`;

const BreadCrumbTitle = ({
  categories,
  history,
  currentQuery,
  totalElements,
  translate,
  user,
  loadBrands,
  listing,
  brandId,
  brands,
  activeLanguage,
}) => {
  const t = (tag) => `catalog.${tag}`;

  const lang = activeLanguage?.code || 'en';
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [brandImageUrl, setBrandImageUrl] = useState('');
  const [brandDescription, setBrandDescription] = useState('');
  const [lastLabel, setLastLabel] = useState('');

  useEffect(() => {
    // Load brands on component mount or language change
    loadBrands(lang);
  }, [lang, loadBrands]);

  useEffect(() => {
    let selectedBrand = null;
    const selectedBrandId = brandId || currentQuery?.brand;
    const newBreadcrumbs = [translate('catalog.title')];
    setLastLabel(translate('catalog.title'));

    const getBrandById = (brandId) => {
      if (!brandId) return null;
      
      const numericId = Number(brandId);
      for (const brandGroup of brands) {
        const foundBrand = brandGroup[1]?.find(
          (brand) => brand.id === numericId
        );
        if (foundBrand) return foundBrand;
      }
      return null;
    };

    // Resolve the brand from `brandId` or `pathname`
    if (selectedBrandId) {
      selectedBrand = getBrandById(selectedBrandId);
    }

    if (selectedBrand) {
      setBrandDescription(selectedBrand.description || '');
      setBrandImageUrl(selectedBrand.imageUrl || '');
      setLastLabel(selectedBrand.name || '');
      newBreadcrumbs.push(selectedBrand.name);
    }

    // Add search term
    if (currentQuery?.q) {
      const searchQuery = translate('catalog.search') + `"${currentQuery.q}"`;
      newBreadcrumbs.push(searchQuery);
      setLastLabel(searchQuery);
    }

    // Add display type
    if (currentQuery?.display) {
      const displayTitles = {
        NEW: translate('loggedInHome.new.title'),
        BEST_SELLER: translate('loggedInHome.hot.title'),
        CANADIAN: translate('loggedInHome.canadian.title'),
        LISTING:
          listing.find((o) => o.id === currentQuery?.displayId)?.name ||
          translate('loggedInHome.featured.title'),
      };
      const displayTitle = displayTitles[currentQuery?.display];
      if (displayTitle) {
        newBreadcrumbs.push(displayTitle);
        setLastLabel(displayTitle);
      }
    }

    // Add categories
    const group = categories.find(({ id }) => id == currentQuery?.group);
    if (group) {
      newBreadcrumbs.push(group.name);
      setLastLabel(group.name);

      const category = group.categoryList?.find(
        ({ id }) => id == currentQuery?.category
      );
      if (category) {
        newBreadcrumbs.push(category.name);
        setLastLabel(category.name);

        const type = category.typeList?.find(
          ({ id }) => id == currentQuery?.type
        );
        if (type) {
          newBreadcrumbs.push(type.name);
          setLastLabel(type.name);
        }
      }
    }

    // Avoid unnecessary re-renders
    setBreadcrumbs(newBreadcrumbs);

  }, [currentQuery, categories, listing, translate, lang, history, brandId, brands]);


  // Reset filter for entire page
  const resetFilters = () => {
    history.push({ pathname: `/${lang}/catalog` });
  };

  useEffect(() => {
    // Update document title
    if (breadcrumbs?.length) {
      document.title = breadcrumbs.join(' > ') + ' - Loyalty Source';
    }
  }, [breadcrumbs]);

  return (
    <>
      <Breadcrumbs>
        {breadcrumbs.length > 0 &&
          breadcrumbs.map((bread, index) => (
            <Bread
              key={'catalog-breadcrumb-' + index + bread}
              isFirst={index === 0}
              isLast={breadcrumbs.length === index + 1}
              onClick={() => {
                if (index === 0) {
                  resetFilters();
                }
              }}
            >
              {bread.trim()}
            </Bread>
          ))}
      </Breadcrumbs>
      <Title
        text={lastLabel}
        imgSrc={brandImageUrl}
        productCounter={true}
        totalProducts={
          totalElements > 0 ? formatNum(totalElements, lang) : null
        } // total product num
        productCountsLabel={
          totalElements !== 1
            ? translate(t('productLabel_other'))
            : translate(t('productLabel_1'))
        }
        lang={lang}
        history={history}
        currentQuery={currentQuery}
        user={user}
        brandImageUrl={brandImageUrl}
        brandDescription={brandDescription ? fixHtmlEncodings(convert(brandDescription)) : null}
      />
    </>
  );
};
function mapStateToProps({ brands }) {
  return {
    brands,
  };
}

const mapDispatchToProps = {
  loadBrands,
};

export default withLocalize(connect(
  mapStateToProps,
  mapDispatchToProps
)(BreadCrumbTitle));