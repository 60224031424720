import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { colors, spacing } from '../../../../defaults/styles';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { convert, fixHtmlEncodings } from '../../../../tools/helpers/descriptionConverter';

const BrandImage = styled.img`
    display: block;
    border: 1px solid;
    border-color: inherit;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${spacing.verySmall}px;
    padding: ${spacing.small}px;
    max-width: ${props => props.isMobile ? '140' : '180'}px;
  `;

const Brand = ({ brand, brandClickHandler, url, isMobile }) => {
  const { imageUrl, name, id, description } = brand;

  const [hovering, setHovering] = useState(false);

  return (
    <Link
      style={{
        borderColor: hovering ? colors.main.normal : colors.gray.light,
        color: hovering ? colors.primary.normal : colors.black.normal,
        textAlign: 'center',
        textDecoration: 'none',
      }}
      // TODO: find a better way to do the anchor
      // to={url+'#anchor'}
      to={url}
      title={fixHtmlEncodings(convert(description))}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <BrandImage src={imageUrl} alt={name} isMobile={isMobile} />
      {/* <div>{name}</div> */}
    </Link>
  );
};

export default Brand;
