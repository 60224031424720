import React, {useEffect} from 'react';
import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import { useSelector, useDispatch } from 'react-redux';
import { loadBrandCount } from '../../../../redux/actions/brandActions';
import { withLocalize } from 'react-localize-redux';

const Intro = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `brand.${tag}`;
  const brandCount = useSelector((state) => state.brandCount);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadBrandCount());
  }, [dispatch]);

  const dynamicTitle = translate('brand.title', { count: brandCount || '380' }); // Fallback to 380 if brandCount is not yet loaded

  return (
    <UIContainer fullWidth noPadding>
      <UISection noPadding>
        <PageReinforcementStatic
          id={'brandPageReinforcementStatic'}
          title={dynamicTitle}
          subTitle={translate(t('subTitle'))}
          alignment={'center'}
          borderRequired
          heading={'h1'}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      </UISection>
    </UIContainer>
  );
};

export default withLocalize(Intro);
