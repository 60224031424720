import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { spacing, colors } from '../../../../../defaults/styles';
import { selectThemeOverride } from '../../../../../tools/helpers/styling';
import Select from 'react-select';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import qs from 'querystring';

const BrandDiv = styled.div`
    border-top: 1px solid ${colors.gray.light};
    padding-top: ${spacing.medium}px;
    ${props => props.isMobile ? 'padding-right: ' + spacing.verySmall + 'px' : ''};
    padding-left: ${spacing.verySmall}px;
    ${props => props.isMobile ? 'margin-bottom:' + spacing.large + 'px' : ''};
    margin-top: ${spacing.large}px;
`;

const BrandTitleWrapper = styled.div`
    font-weight: bold;
    margin-bottom: ${spacing.verySmall}px;
`;

const Brands = ({
    brands,
    currentQuery,
    history,
    isMobile,
    translate
}) => {
    const t = (tag) => `corporateGifts.brand.${tag}`;

    const brandsList = [];
    brands.forEach(brandGroup => {
        brandGroup[1].forEach(brand => {
            brandsList[brand.id] = brand;
        });
    });

    const selectedBrand = currentQuery && (currentQuery.brand == 0 || currentQuery.brand > 0) 
        ? brandsList[currentQuery.brand] 
        : null;

    // Sort by alphabetical order
    brandsList.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

    const options = brandsList.map(brand => ({
        value: brand.id,
        label: brand.name,
        imageUrl: brand.imageUrl,
    }));

    // Load query
    const separatedQuery = currentQuery ? JSON.parse(JSON.stringify(currentQuery)) : {};

    delete separatedQuery.brand;
    delete separatedQuery.brandName;
  
    // Applying filter
    const pushHistory = (brand) => {
        if (brand) {
            if (separatedQuery) {
                delete separatedQuery.productFeatureValueId;
                history.push({
                    pathname: history.location.pathname,
                    search: '?' + qs.stringify({ ...separatedQuery, brand: brand.value, brandName: brand.label })
                });
            } else {
                history.push({
                    pathname: history.location.pathname,
                    search: '?' + qs.stringify({ brand: brand.value, brandName: brand.label })
                });
            }
        } else {
            history.push({
                pathname: history.location.pathname,
                search: '?' + qs.stringify(separatedQuery)
            });
        }
    }

    return (
        <BrandDiv isMobile={isMobile}>
            <BrandTitleWrapper>
                <Translate id={t('title')} />
            </BrandTitleWrapper>

            <div>
                <Select
                    value={
                        selectedBrand
                        ? {
                            value: selectedBrand.id,
                            label: selectedBrand.name,
                            imageUrl: selectedBrand.imageUrl,
                        }
                        : null
                    }
                    isClearable={true}
                    options={options}
                    placeholder={translate(t('selectPlaceholder'))}
                    theme={theme => selectThemeOverride(theme)}
                    noOptionsMessage={() => translate(t('loading'))}
                    onChange={pushHistory}
                />
            </div>
        </BrandDiv>
    );
};

export default withRouter(withLocalize(Brands));