import React from 'react';
import { spacing, colors } from '../../../../defaults/styles';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import ResultWrapper from './ResultWrapper';

const ListContent = styled.div`
    padding-left: ${props => props.isMobile ? spacing.small : spacing.medium}px;
    ${props => props.isMobile ? 'padding-right: ' + spacing.small + 'px' : ''};
    ${props => props.isMobile ? '' : 'margin-left: ' + spacing.medium + 'px'};
    ${props => props.isMobile ? '' : 'border-left: 1px solid ' + colors.gray.light};
    overflow-x: hidden;
`;

const CatalogContent = ({
    currentQuery,
    isMobile,
    categories,
    user,
    apiCallsInProgress,
    catalogForCorporateGifts,
    loadCatalogForCorporateGifts,
    brands,
    brandId,
    totalElements,
    totalPages,
    listing,
    history,
}) => {
    return (
        <ListContent isMobile={isMobile}>
            <ResultWrapper 
                categories={categories}
                isMobile={isMobile} 
                currentQuery={currentQuery} 
                user={user}
                apiCallsInProgress={apiCallsInProgress}
                catalogForCorporateGifts={catalogForCorporateGifts}
                brands={brands}
                brandId={brandId}
                totalElements={totalElements}
                totalPages={totalPages}
                listing={listing}
                loadCatalogForCorporateGifts={loadCatalogForCorporateGifts}
                history={history}
            />
        </ListContent>
    );
}

export default CatalogContent;