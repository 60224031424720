import * as types from '../actions/types'
import { blogPosts } from './initialState'

export default function blogPostsReducer(state = blogPosts, actions) {
    switch (actions.type) {
        case types.LOAD_BLOGS_SUCCESS:{
            return {...state, blogs: actions.blogs}
        }
        case types.GET_BLOG_POST_BY_ID:{
            return {...state, blog: actions.blogs}
        }
        case types.GET_BLOG_POST_BY_TITLE:{
            return {...state, blog: actions.blogs}
        }
        default: 
            return state
    }
}