import * as types from './types'
import { blogs } from '../../tools/api'
import { beginApiCall, apiCallError } from './apiStatusActions'

export const loadBlogsSuccess = blogs => {
    return { type: types.LOAD_BLOGS_SUCCESS, blogs}
}

export const loadBlogByIdSuccess = blogs => {
    return { type: types.GET_BLOG_POST_BY_ID, blogs}
}

export const loadBlogByTitleSuccess = blogs => {
    return { type: types.GET_BLOG_POST_BY_TITLE, blogs}
}

export const loadBlogPosts = (pageNumber, language) => {
    return dispatch => {
        dispatch(beginApiCall());
        return blogs
        .getAllBlogs(pageNumber, language)
        .then(({ json: blogs }) => {
            dispatch(loadBlogsSuccess(blogs))
        })
        .catch(error => {
            dispatch(apiCallError(error))
            throw error
        })
    }
}
 
export const findBlogById = (id) => {
    return dispatch => {
        dispatch(beginApiCall())
        return blogs
        .getBlogById(id)
        .then(({ json: blogs }) => {
            dispatch(loadBlogsSuccess(blogs))
        })
        .catch(error => {
            dispatch(apiCallError(error))
            throw error
        })
    }
}

export const findBlogByTitle = (title) => {
    return dispatch => {
        dispatch(beginApiCall())
        return blogs
        .getBlogByTitle(title)
        .then(({ json: blogs }) => {
            dispatch(loadBlogsSuccess(blogs))
        })
        .catch(error => {
            dispatch(apiCallError(error))
            throw error
        })
    }
}