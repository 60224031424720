import React, { useContext, useEffect } from 'react';
import UIMain from "../../interface/UIMain";
import UISection from '../../interface/UISection';
import { withLocalize } from "react-localize-redux";
import { sizing } from '../../../../defaults/styles';
import qs from 'querystring';
import { CategoriesContainer } from '../../../smart';
import CategoriesSideBar from '../../layout/CategoriesSideBar';
import CatalogContent from './CatalogContent';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { NewCatalogContainer as CatalogContainer } from "../../../smart";
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';

const categoriesAsideWidth = sizing.categoriesAsideWidth;

const sectionStyle = (isMobile) => isMobile
    ? {
        maxWidth: sizing.contentWidth,
        margin: '2em auto',
        display: 'flex',
        flexDirection: 'column',
    }
    : {
        maxWidth: sizing.contentWidth,
        margin: '2.5em auto',
        display: 'grid',
        gridTemplateColumns: `${categoriesAsideWidth}px ${sizing.contentWidth -
            categoriesAsideWidth}px`,
    };
const ErrorSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
    max-width: ${sizing.contentWidth}px;
    margin: 2.5em auto;
  `;

const CategoryPage = ({ history, translate, brandId, specificBrandName, error, ...props }) => {
    const { isMobile, isTablet } = useContext(ResizeAwareContext);

    const currentQuery = specificBrandName
        ? { brand: String(brandId) }
        : (props.location && props.location.search && qs.parse(props.location.search.substr(1))) || {};

    useEffect(() => {
        // If specificBrandName exists, set up the page based on the brand
        if (specificBrandName) {
            document.title = `${translate("catalog.brandCatalog")} - ${specificBrandName}`;
        } else {
            document.title = translate("catalog.defaultTitle");
        }
    }, [specificBrandName, translate]);

    return (
        <UIMain isMobile={isMobile} isTablet={isTablet}>
            {error ?
                <ErrorSection>
                    {error}
                </ErrorSection>
                :
                <UISection noPadding customStyle={sectionStyle(isMobile)}>
                    <CategoriesContainer>
                        <CatalogContainer
                            history={history}
                            brandId={brandId}
                        >
                            <CategoriesSideBar
                                isMobile={isMobile}
                                history={history}
                                currentQuery={currentQuery}
                                brandId={brandId}
                            />

                            <CatalogContent
                                isMobile={isMobile}
                                history={history}
                                currentQuery={currentQuery}
                                brandId={brandId}
                            />
                        </CatalogContainer>
                    </CategoriesContainer>
                </UISection>}
        </UIMain>
    );
};

export default withLocalize(CategoryPage);