import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/pro-regular-svg-icons';
import { faCartPlus as fasCartPlus } from '@fortawesome/pro-solid-svg-icons';import { Translate, withLocalize } from 'react-localize-redux';
import { spacing, colors } from '../../../../../../../defaults/styles';
import GridLoader from 'react-spinners/GridLoader';
import ItemCounter from '../../../../../interface/ItemCounter';
import { lsStyled as styled } from '../../../../../../../tools/helpers/lsStyled';

const AddToCart = ({ 
  sku, 
  statusId,
  user, 
  activeLanguage,
  translate,
  cart,
  addCartItem,
  isMobile,
}) => {
  const t = (tag) => `product.info.${tag}`;

  const logInRequired = user && !user.loggedIn;

  const [ hovered, setHovered ] = useState(false);
  const [ item, setItem ] = useState({quantity:0});

  useEffect(() => {
    if(cart && cart.items) {
      const newItem = cart.items.find((item) => item.sku === sku);
      if(newItem) {
        setItem(newItem);
      } else {
        setItem({quantity:0});
      }
    }
  }, [ cart ])

  const onClick = () => {
    if(cart && item.quantity < 99999) {
      addCartItem(sku, );
      setItem({...item, quantity: item.quantity + 1 });
    }
  }

  return logInRequired || statusId === 4 ?(<></>):(
    <Button 
      hovered={hovered}
      onClick={onClick}
      //Only darken if item quantity is less than 99999
      onMouseEnter={()=>setHovered(item.quantity < 99999)}
      onMouseLeave={()=>setHovered(false)}
      title={translate(t('addToCart'))}
      isMobile={isMobile}
    >
      {!cart ? (
        <GridLoader
          css={{ margin: 'auto' }}
          color={colors.white}
          size={12}
        />
      ):(
        <TextWrapper>
          <IconBox>
            <FontAwesomeIcon icon={item.quantity  ? fasCartPlus : faCartPlus} />
          </IconBox>
          {translate(t('addToCart'))}

          {item.quantity > 0 && (
            <ItemCounter
                isDiv={false}
                extend={'right'}
                marginLeft={0.25}
                count={item.quantity}
                activeLanguage={activeLanguage}
                style={{
                  position:'relative',
                  fontSize:'inherit'
                }}
                size={1.5}
            />)}
        </TextWrapper>
      )}
    </Button>
  );
};

const Button = styled.div`
  background-color: ${props => !props.hovered ? colors.primary.normal : colors.primary.dark};
  border-radius: 0.25rem;
  border-top: 1px solid ${colors.gray.light};
  color: ${colors.white.normal};
  cursor: pointer;
  max-width: ${props => props.isMobile ? '95%' : '250px'};
  padding-top: ${spacing.small}px;
  padding-bottom: ${spacing.small}px;
  transition: all 250ms ease-in-out 0s;
`;

const TextWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
`;

const IconBox = styled.div`
  margin-right: 5
`;
export default withLocalize(AddToCart);