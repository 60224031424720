import React, { useContext } from 'react';
import UIMain from './../../interface/UIMain';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { withLocalize } from 'react-localize-redux';
import Intro from './Intro';
import { Helmet } from 'react-helmet-async';

const ThankYouPage = ({ translate, activeLanguage }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);
  const language = activeLanguage?.code ? activeLanguage.code : 'en';

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <Helmet>
        <title>
          {translate('thankYou.title')}
        </title>
        <meta
          name="description"
          content={translate('thankYou.metaDescription')}
        />
        <meta
          name="keywords"
          content={translate('thankYou.metaKeywords')}
        />
      </Helmet>
      <Intro isMobile={isMobile} isTablet={isTablet} />
    </UIMain>
  );
};

export default withLocalize(ThankYouPage);