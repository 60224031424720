import React from 'react';
import Moment from 'react-moment';
import 'moment/locale/fr';
import { colors, fonts, spacing } from './../../../../defaults/styles';

import { withLocalize, Translate } from 'react-localize-redux';
import { GridLoader } from 'react-spinners';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';

// == Styling == //
const MyAccountContainer = styled.div`
  background-color: ${colors.gray.light};
  border: 1px solid rgba(149, 149, 149, 0.2);
  border-radius: 3px;
  padding: ${spacing.veryLarge}px;
`;

const H1 = styled.h1`
  margin-bottom: ${spacing.large}px;
`;

const InfoList = styled.ul`
  list-style: none;
`;
const InfoListItem = styled.li`
  color: ${colors.gray.dark};
  line-height: 1;
  margin-bottom: ${spacing.small}px;

  &:last-child {
    margin-bottom: 0;
  }
`;
const InfoListItemLabel = styled.span`
  color: ${colors.black.normal};
  font-weight: ${fonts.weights.title};
  margin-right: ${spacing.verySmall}px;
  ${props => props.isMobile ? 'display: block' : ''};
  ${props => props.isMobile ? `margin-bottom: ${spacing.small}px` : ''};
`;

const LoaderWrapper = styled.div`
  padding: ${spacing.veryLarge}px;
`;

const LoadingGrid = (
  <LoaderWrapper>
    <GridLoader css={{ margin: 'auto' }} color={colors.main.normal} size={12} />
  </LoaderWrapper>
);

// == /Styling == //

const UserInformationSection = ({ userInfo, userParentInfo, isMobile, activeLanguage }) => {
  if (userInfo && userParentInfo) {
    const t = (tag) => `userInfo.${tag}`;
    return (
      <MyAccountContainer>
        <H1>
          <Translate id={t('title')} />
        </H1>
        <InfoList>
          <InfoListItem>
            <InfoListItemLabel isMobile={isMobile}>
              <Translate id={t('accountOwnerLabel')} />
            </InfoListItemLabel>
            {userParentInfo.username}
          </InfoListItem>
          <InfoListItem>
            <InfoListItemLabel isMobile={isMobile}>
              <Translate id={t('usernameLabel')} />
            </InfoListItemLabel>
            {userInfo.username}
          </InfoListItem>
          <InfoListItem>
            <InfoListItemLabel isMobile={isMobile}>
              <Translate id={t('memberSinceLabel')} />
            </InfoListItemLabel>
            <Moment
              format={
                activeLanguage?.code === 'en'
                  ? 'MMMM D, YYYY'
                  : 'D MMMM YYYY'
              }
              locale={activeLanguage?.code}
            >
              {userInfo.creationDate}
            </Moment>
          </InfoListItem>
          <InfoListItem>
            <InfoListItemLabel isMobile={isMobile}>
              <Translate id={t('companyAddressLabel')} />
            </InfoListItemLabel>
            {userInfo.address}
          </InfoListItem>
          <InfoListItem>
            <InfoListItemLabel isMobile={isMobile}>
              <Translate id={t('accountManagerNameLabel')} />
            </InfoListItemLabel>
            {userInfo.accountManagerName ? (
              <span>{userInfo.accountManagerName}</span>
            ) : (
              <Translate id={t('accountManagerNotAssignedText')} />
            )}
          </InfoListItem>
          <InfoListItem>
            <InfoListItemLabel isMobile={isMobile}>
              <Translate id={t('accountManagerEmailLabel')} />
            </InfoListItemLabel>
            {userInfo.accountManagerEmail ? (
              <span>{userInfo.accountManagerEmail}</span>
            ) : (
              <Translate id={t('accountManagerNotAssignedText')} />
            )}
          </InfoListItem>
        </InfoList>
      </MyAccountContainer>
    );
  } else {
    return (LoadingGrid);
  }
};

export default withLocalize(UserInformationSection);
