import { LSFetch } from '../helpers'

export const getBlogById = (id) => {
    return LSFetch.get(`/blogs/retrieve/blogs/${id}`);
}

export const getBlogByTitle = (title) => {
    return LSFetch.get(`/blogs/retrieve/blog/${title}`);
}

export const getAllBlogs = (pageNumber, language) => {
    return LSFetch.get(`/blogs/get/all/blogs/${pageNumber}`,{ params: { language } })
}
