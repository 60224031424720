import * as types from '../actions/types';
import { showUserMenu, showShoppingListPopup } from './initialState';

const initialState = {
    showUserMenu,
    showShoppingListPopup,
};

export default function togglePopupReducer(state = initialState, action) {
    switch (action.type) {
        case types.TOGGLE_USER_MENU:
            return {
                ...state,
                showUserMenu: action.payload,
            };
        case types.TOGGLE_SHOPPING_LIST:
            return {
                ...state,
                showShoppingListPopup: action.payload,
            };
        default:
            return state;
    }
}
