import React, { useContext } from 'react';
import UIMain from './../../interface/UIMain';
import Intro from './Intro';
import GiftingExperience from './GiftingExperience';
import Catalog from './Catalog';
import { withLocalize } from 'react-localize-redux';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import ShowCatalog from './ShowCatalog';
import { Helmet } from 'react-helmet-async';

const ServicesGiftingPage = ({ translate, history }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <Helmet>
        <title>{translate('services.gifting.title')}</title>
        <meta
          name="description"
          content={translate('services.gifting.giftDescription')}
        />
        <meta
          name="keywords"
          content={translate('services.gifting.giftKeywords')}
        />
      </Helmet>
      <Intro isMobile={isMobile} isTablet={isTablet} />
      <GiftingExperience isMobile={isMobile} isTablet={isTablet} />
      <Catalog isMobile={isMobile} isTablet={isTablet} history={history}/>
      <ShowCatalog isMobile={isMobile} isTablet={isTablet} />
    </UIMain>
  );
};

export default withLocalize(ServicesGiftingPage);
