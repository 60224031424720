import React from 'react';
import { colors, spacing, fonts } from '../../../../defaults/styles';
import PulseLoader from 'react-spinners/PulseLoader';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import Filters from './Filters';

const TitleWrapper = styled.header`
  display: block;
  align-items: flex-end;
`;

const BrandTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const CategorySubtitle = styled.span`
  color: ${colors.gray.normal};
  flex: 1 1 0;
  margin-top: ${spacing.small}px;
  margin-left: 0;
  display: block;
`;

const SubTitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: end;
`;

const BrandImage = styled.img`
    display: block;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: ${spacing.verySmall}px;
    padding: ${spacing.small}px;
    max-width: 140px;
`;

const Description = styled.span`
    color: ${colors.gray.normal};
    font-size: ${fonts.sizes.medium}px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0;
    margin-right: auto;
    display: block;
`;

const CategoryTitle = styled.h2`
  font-size: ${fonts.sizes.title}px;
  ${'' /* the next minus margin is a temporary solution for h2 alignment due to the font family */}
  margin-bottom: 0.2em;
  padding-top: 0.2em;
  line-height: 1.2em;
`;

const Title = ({
  text,
  productCounter,
  totalProducts,
  productCountsLabel,
  history,
  currentQuery,
  user,
  brandImageUrl,
  brandDescription,
}) => {
  const fixHtmlEncodings = (string) => {

    string = string.replace(/&amp;/mg, '&');
    string = string.replace(/&quot;/mg, '"');
    string = string.replace(/&quote;/mg, '"');
    string = string.replace(/&#95;/mg, '_');
    string = string.replace(/&ndash;/mg, '-');
    string = string.replace(/&mdash;/mg, '—');
    string = string.replace(/&#39;/mg, "'");
    string = string.replace(/&rsquo;/mg, "‘");
    string = string.replace(/&lsquo;/mg, "‘");
    string = string.replace(/&#34;/mg, '"');
    string = string.replace(/&ldquo;/mg, '“');
    string = string.replace(/&rdquo;/mg, '”');
    string = string.replace(/&#62;/mg, '>');
    string = string.replace(/&#60;/mg, '<');
    string = string.replace(/&nbsp;/mg, ' ');
    string = string.replace(/&eacute;/mg, 'é');
    string = string.replace(/&Eacute;/mg, 'É');
    return string;
  }

  const convert = (description) => {
    return description ? typeof (description) === 'string'
      ? description.replace(/<[^>]+>/g, '')
      : description.toString().replace(/<[^>]+>/g, '')
      : 'To Be Continued';
  }
  // TODO: jump filter menu
  // const anchor = React.useRef(null);
  // const location = useLocation();

  // useEffect(() => {
  //   console.log("come into useeffect")
  //   //Scroll to location
  //   if (anchor && anchor.current && location.hash.includes('#anchor')) {
  //     // eslint-disable-next-line no-unused-expressions
  //     console.log("come into anchor")
  //     anchor.current.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start',
  //       inline: 'center'
  //     });
  //   }
  //   const element = document.getElementById("anchor");
  //   if (element) {
  //     element.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start',
  //       inline: 'center'
  //     });
  //   }

  //   return () => {
  //     console.log("cleanup")
  //   }
  // }, [anchor, location.hash])

  return (
    <TitleWrapper>
      {
        !brandDescription && !brandImageUrl ?
          <CategoryTitle>{text ? text : <PulseLoader size={8} />}</CategoryTitle>
          : <BrandTitle>
            {brandDescription || brandImageUrl ?
              <BrandImage src={brandImageUrl} />
              : <PulseLoader size={8} />}
            <Description>{fixHtmlEncodings(convert(brandDescription))}</Description>
          </BrandTitle>
      }
      {productCounter && totalProducts ? (
        <SubTitleWrapper>
          <CategorySubtitle>
            <a id="anchor">{totalProducts}</a> {productCountsLabel}
          </CategorySubtitle>
          <Filters history={history} currentQuery={currentQuery} user={user} />
        </SubTitleWrapper>
      ) : null}
    </TitleWrapper>
  );
};

export default Title;
