import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { loadCategories } from '../../../redux/actions/categoryActions';
import { setSelectedCategory } from '../../../redux/actions/selectedCategoryActions';
import {
  removeSelectedBrand,
  setSelectedBrand,
} from '../../../redux/actions/selectedBrandActions';
import { withLocalize } from 'react-localize-redux';
import { setPreviousCategoryFilters } from '../../../redux/actions/previousCategoryFiltersActions';

export const CategoriesContainer = ({
  shouldLoadCategories,
  loadCategories,
  selectedCategory,
  setSelectedCategory,
  categories,
  activeLanguage,
  selectedBrand,
  removeSelectedBrand,
  previousCategoryFilters,
  ...props
}) => {
  useEffect(() => {
    if (activeLanguage) {
      const language = activeLanguage?.code || 'en';

      if (previousCategoryFilters.language !== language) {
        loadCategories(language);
      }
    }
  }, [loadCategories, activeLanguage]);

  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, {
      categories,
      selectedCategory,
      setSelectedCategory,
      selectedBrand,
      removeSelectedBrand,
    })
  );
  return <>{childrenWithProps}</>;
};

CategoriesContainer.propTypes = {
  loadCategories: propTypes.func.isRequired,
  categories: propTypes.array.isRequired,
  selectedCategory: propTypes.object.isRequired,
  setSelectedCategory: propTypes.func.isRequired,
  removeSelectedBrand: propTypes.func.isRequired,
};

function mapStateToProps({
  categories,
  selectedCategory,
  selectedBrand,
  previousCategoryFilters,
}) {
  return {
    categories,
    selectedCategory,
    selectedBrand,
    previousCategoryFilters,
  };
}

const mapDispatchToProps = {
  loadCategories,
  setSelectedCategory,
  removeSelectedBrand,
  setSelectedBrand,
  setPreviousCategoryFilters,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(CategoriesContainer));
