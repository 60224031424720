import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { colors, fonts, spacing } from '../../../../../defaults/styles';
import PulseLoader from 'react-spinners/PulseLoader';
import qs from 'querystring';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';

const Dropdown = styled.select`
    width: 100%;
    padding: ${spacing.small}px;
    font-size: ${fonts.sizes.medium}px;
    box-sizing: border-box;
    margin-bottom: ${spacing.medium}px;
`;

const CategoriesTitle = styled.div`
    font-weight: bold;
    margin-bottom: ${spacing.verySmall}px;
    padding-left: ${spacing.verySmall}px;
    padding-right: ${spacing.verySmall}px;
`;

const Categories = ({
    categories,
    history,
    activeLanguage,
    currentQuery,
    product,
    translate,
}) => {
    const t = (tag) => `corporateGifts.${tag}`;

    let topId = currentQuery && currentQuery.group ? Number(currentQuery.group) : 0;
    let catId = currentQuery && currentQuery.category ? Number(currentQuery.category) : 0;
    let typeId = currentQuery && currentQuery.type ? Number(currentQuery.type) : 0;

    if (product && product.categoryGroupId) { topId = product.categoryGroupId; }
    if (product && product.categoryId) { catId = product.categoryId; }
    if (product && product.categorySpecificationId) { typeId = product.categorySpecificationId; }

    const separatedQuery = currentQuery ? JSON.parse(JSON.stringify(currentQuery)) : {};

    delete separatedQuery.group;
    delete separatedQuery.groupName;
    delete separatedQuery.category;
    delete separatedQuery.type;

    const lang = activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
    const pathname = `/${lang}/corporate-gifts`;

    const pushHistory = (param = null) => {
        if (separatedQuery) {
            delete separatedQuery.productFeatureValueId;
            history.push({
                pathname: pathname,
                search: '?' + qs.stringify({ ...separatedQuery, ...param })
            });
        } else {
            history.push({
                pathname: pathname,
                search: '?' + qs.stringify(param)
            });
        }
    }

    const slugify = (name) =>
        name
           .normalize("NFD") // Normalize accented characters
           .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
           .replace(/[^a-zA-Z0-9\s]/g, "") // Remove special characters
           .trim()
           .replace(/\s+/g, "-") // Replace spaces with hyphens
           .toLowerCase();

    const handleCategoryChange = (e) => {
        const id = e.target.value;
        if (id === 'viewAll') {
            pushHistory();
        } else {
            // Find the selected group by ID
            const selectedGroup = categories.find((category) => category.id === Number(id));
            
            // If the group is found, include its name in the query parameters
            if (selectedGroup) {
                const forwardFilter = { 
                    group: id, 
                    groupName: slugify(selectedGroup.name)
                };
                pushHistory(forwardFilter);
            } else {
                pushHistory({ group: id });
            }
        }
    };

    return categories.length ? (
        <section>
            <CategoriesTitle>
                <Translate id={t('categoriesTitle')} />
            </CategoriesTitle>
            <Dropdown onChange={handleCategoryChange} value={topId || ''}>
                <option value="" disabled>{translate(t('selectCategory'))}</option>
                {categories.map(({ name: topName, id }) => (
                    <option key={id} value={id}>
                        {topName}
                    </option>
                ))}
                <option value="viewAll">{translate(t('viewAll'))}</option>
            </Dropdown>
        </section>
    ) : (
        <div style={{ textAlign: 'center' }}>
            <PulseLoader
                css={{ margin: 'auto' }}
                color={colors.gray.normal}
                size={12}
            />
        </div>
    );
};

export default withLocalize(Categories);