import React, { useContext } from 'react';
import UIMain from './../../interface/UIMain';

import Intro from './Intro';
import BrandsAndRewards from './BrandsAndRewards';
import ModernMarketplace from './ModernMarketplace';
import SeamlessIntegration from './SeamlessIntegration';
import LoveLoyaltySource from './LoveLoyaltySource';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { withLocalize } from 'react-localize-redux';
import { Helmet } from 'react-helmet-async';

const ServicesRewardsPage = ({ translate }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <Helmet>
        <title>{translate('services.rewards.title')}</title>
        <meta
          name="description"
          content={translate('aboutUs.intro.subTitle')}
        />
        <meta
          name="keywords"
          content="Loyalty Source, Rewards, Marketplace, Catalogue, Benefits, Distribution Center"
        />
      </Helmet>
      <Intro isMobile={isMobile} isTablet={isTablet} />
      <BrandsAndRewards isMobile={isMobile} isTablet={isTablet} />
      <ModernMarketplace isMobile={isMobile} isTablet={isTablet} />
      <SeamlessIntegration isMobile={isMobile} isTablet={isTablet} />
      <LoveLoyaltySource isMobile={isMobile} isTablet={isTablet} />
    </UIMain>
  );
};

export default withLocalize(ServicesRewardsPage);
