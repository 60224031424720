import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { loadCatalog } from '../../../redux/actions/catalogActions';
import qs from 'querystring';
import { withLocalize } from 'react-localize-redux';
import { loadBrands } from '../../../redux/actions/brandActions';
import { loadProductFeatures, loadProductFeaturesByCategorical } from '../../../redux/actions/featureActions';
import { loadListing } from '../../../redux/actions/featureProductsActions';

export const CatalogContainer = ({
    user,
    apiCallsInProgress,
    activeLanguage,
    catalog,
    loadCatalog,
    history,
    brandId,
    loadBrands,
    loadProductFeatures,
    loadProductFeaturesByCategorical,
    categories,
    listing,
    loadListing,
    shoppingLists,
    ...props
}) => {
    const [brands, setBrands] = useState([]);

    const param = {
        ...qs.parse(history.location.search.substr(1)),
        language: activeLanguage?.code || 'en',
        ...(brandId ? { brand: String(brandId) } : {}),
    };

    // Redirect to /catalog if brand is not a number
    if (param.brand && isNaN(Number(param.brand))) {
        history.push(`/${param.language}/brands`);
    }

    useEffect(() => {
        if (props.brands.length === 0) loadBrands(param.language);
        else setBrands([...props.brands]);
    }, [props.brands, loadBrands, props.features]);

    useEffect(() => {
        if (activeLanguage) {
            loadListing(param);
        }
    }, [history.location, props.listing, activeLanguage]);

    useEffect(() => {
        if (activeLanguage) {
            loadCatalog(param, 0);
        }
    }, [history.location, activeLanguage]);

    const totalPages = catalog[0]?.totalPages || 0;
    const totalElements = catalog[0]?.totalElements || 0;

    const childrenWithProps = React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
            user,
            apiCallsInProgress,
            catalog,
            loadCatalog,
            history,
            totalPages,
            totalElements,
            brands,
            categories,
            listing,
            shoppingLists,
        })
    );

    return <>{childrenWithProps}</>;
};

CatalogContainer.propTypes = {
    user: propTypes.object.isRequired,
    apiCallsInProgress: propTypes.number.isRequired,
    catalog: propTypes.array.isRequired,
    loadCatalog: propTypes.func.isRequired,
    loadBrands: propTypes.func.isRequired,
    brands: propTypes.array.isRequired,
    loadProductFeaturesByCategorical: propTypes.func.isRequired,
    loadListing: propTypes.func.isRequired,
    listing: propTypes.array.isRequired,
};

const mapStateToProps = ({ user, catalog, apiCallsInProgress, brands, listing, shoppingLists }) => ({
    user,
    catalog,
    apiCallsInProgress,
    brands,
    listing,
    shoppingLists,
});

const mapDispatchToProps = {
    loadCatalog,
    loadBrands,
    loadProductFeatures,
    loadProductFeaturesByCategorical,
    loadListing,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(CatalogContainer));