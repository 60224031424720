import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { CartContainer } from '../../../smart';
import CartContent from './CartContent';
import { Helmet } from 'react-helmet-async';

const CheckoutPage = ({ translate, history }) => {

    return (
        <CartContainer history={history}>
            <Helmet>
                <title>{translate('checkout.title')}</title>
                <meta
                    name="description"
                    content={translate('checkout.description')}
                />
                <meta
                    name="keywords"
                    content={translate('checkout.keywords')}
                />
                <meta name="author" content="Loyalty Source" />
            </Helmet>
            <CartContent />  
        </CartContainer>
    );
};

export default withLocalize(CheckoutPage);