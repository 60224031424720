import React, { useRef, useState, useEffect } from 'react';
import { spacing, colors } from '../../../../defaults/styles';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { GridLoader } from 'react-spinners';
import { Translate, withLocalize } from 'react-localize-redux';
import { getAccessTokenToCreateProject, getItAccountShortToken } from '../../../../tools/api/zoomCatalog';
import List from '../../layout/ProductsList/List';
import BreadCrumbTitle from '../Shared/BreadCrumbTitle';
import Button from '../../interface/Button';
import ZoomPopup from '../ProductPage/Product/Content/ZoomCatalog/ZoomPopup';
import { connect } from 'react-redux';

const ResultHeader = styled.header`
  ${props => (props.isMobile ? 'display: flex' : '')};
  ${props => (props.isMobile ? 'flex-direction: column' : '')};
  ${props => (props.isMobile ? '' : 'align-items: center')};
  ${props =>
    props.isMobile ? '' : 'margin-bottom: ' + spacing.large + 'px'};
`;

const LoaderWrapper = styled.div`
  padding: ${spacing.veryLarge}px;
`;

const CatalogBlock = styled.section`
  display: block;
  text-align: center;
`;

const ContentSection = styled.section`
  padding-bottom: ${spacing.veryLarge}px;
`;

const ButtonDiv = styled.div`
  padding-bottom: 1.5em;
`;

const LoadingGrid = (
  <LoaderWrapper>
    <GridLoader css={{ margin: 'auto' }} color={colors.main.normal} size={12} />
  </LoaderWrapper>
);

const ResultWrapper = ({
  categories,
  apiCallsInProgress,
  catalog,
  loadCatalog,
  history,
  totalElements,
  totalPages,
  isMobile,
  currentQuery,
  user,
  brands,
  listing,
  userInfo,
  brandId,
}) => {
  const t = (tag) => `catalog.${tag}`;

  const ContentHolder = content => (
    <ContentSection>
      <ResultHeader isMobile={isMobile}>
        <div>
          <BreadCrumbTitle
            user={user}
            categories={categories}
            totalElements={totalElements}
            history={history}
            currentQuery={currentQuery}
            brands={brands}
            brandId={brandId}
            listing={listing}
          />
        </div>
      </ResultHeader>
      {content}
    </ContentSection>
  );

  // Lazy loading
  const ref = useRef(null);

  const [loadingCatalog, setLoadingCatalog] = useState(true);
  const [viewUrl, setViewUrl] = useState("");
  const [editUrl, setEditUrl] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [zoomStudioUserToken, setZoomStudioUserToken] = useState("");
  const [zoomStudioItToken, setZoomStudioItToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const appendErrorMessage = (newMessage) => {
    setErrorMessage((prevMessage) => {
      return prevMessage + (prevMessage ? '\n' : '') + newMessage
    });
  };

  useEffect(() => {
    catalog && catalog.length > 0 && setLoadingCatalog(false);
  }, [catalog])

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const itToken = await getItAccountShortToken();
        setZoomStudioItToken(itToken);

        if (isMounted && userInfo && Object.keys(userInfo).length > 0) {
          const data = await getAccessTokenToCreateProject(userInfo.username);
          setZoomStudioUserToken(data.user_token);
        }
      } catch (error) {
        setZoomStudioUserToken(null);
      }
    };

    if (userInfo && Object.keys(userInfo).length > 0) {
      fetchData();
    }

    // Cleanup function to cancel any ongoing tasks when component unmounts
    return () => {
      isMounted = false;
    };
  }, [userInfo])

  // Check if first results are loaded yet
  if (loadingCatalog && (catalog.length === 0 || !catalog)) {
    return ContentHolder(LoadingGrid);
  } else {
    // Dummy page section
    const ary = Array.from(Array(totalPages).keys());

    // find next page
    const nextPage = catalog.length > totalPages - 1 ? -1 : catalog.length;
    
    const loadNextPage = () => {
      setLoadingCatalog(true)
      loadCatalog(currentQuery, nextPage);
    };

    const handleScroll = () => {
      if (ref && ref.current) {
        const top = ref.current.getBoundingClientRect().top;
        if (top < window.innerHeight && !apiCallsInProgress) {
          ref.current.click();
        }
      }
    };

    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);

    const loadedProducts = [];
    ary.forEach((index) => {
      if (catalog[index] && catalog[index].content) {
        catalog[index].content.forEach((product) => {
          loadedProducts.push(product);
        });
      }
    });

    // Loading content
    const content =
      totalElements === 0 ? (
        <Translate id={t('noProducts')} />
      ) : (
        <>
          <List
            loggedInHome={user.loggedIn} 
            products={loadedProducts} 
            isMobile={isMobile}  
            currentQuery={currentQuery}
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            editUrl={editUrl}
            viewUrl={viewUrl}
            setEditUrl={setEditUrl}
            setViewUrl={setViewUrl}
            zoomStudioUserToken={zoomStudioUserToken}
            zoomStudioItToken={zoomStudioItToken}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            appendErrorMessage={appendErrorMessage} 
          />
          {ary.map((value, index) => (
            <CatalogBlock 
              key={'product-block-' + index}
            >
              {catalog[value] && catalog[value].content ? (
                <></>
              ) : index === nextPage ? (
                loadingCatalog ? (
                  LoadingGrid
                ) : (
                  <ButtonDiv>
                    <Button>
                      <span ref={ref} onClick={loadNextPage}>
                        Load more
                      </span>
                    </Button>
                  </ButtonDiv>
                )
              ) : (
                <></>
              )}
            </CatalogBlock>
          ))}
          <ZoomPopup
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            isMobile={isMobile}
            editUrl={editUrl}
            viewUrl={viewUrl}
            zoomStudioUserToken={zoomStudioUserToken}
            zoomStudioItToken={zoomStudioItToken}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            appendErrorMessage={appendErrorMessage}
          />
        </>
      );

    return ContentHolder(content);
  }
};

function mapStateToProps({ userInfo }) {
  return { userInfo };
}
export default withLocalize(connect(
  mapStateToProps,
)(ResultWrapper));
