import React, { useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadNewsEntries } from '../../../redux/actions/newsActions';
import { lsStyled as styled } from '../../../tools/helpers/lsStyled';

const linkStyle = {
    margin: "1rem",
    textDecoration: "none",
    color: 'black'
}

export const NewsContainer = ({ newsEntries, loadNewsEntries, isMobile, activeLanguage }) => {

    useEffect(() => {
        loadNewsEntries();
    }, [])
    var entries = newsEntries.news
    return (
        <>
            <Section>
                {entries?.length > 0 ? entries.filter(singleNews => singleNews.newsLanguage === (activeLanguage.code === 'en' ? 1 : 2)).map(news => {
                    return (
                        <NewsCard isMobile={isMobile} key={news.newsTitle}>
                            <Link to= {{pathname : `${news.newsUrl}`}} target="_blank" style={linkStyle}>
                                <TheNewsContainer>
                                    <b>{news.newsTitle}</b>
                                    <p>{news.newsText}</p>
                                    <b>{news.newsFooter}</b>
                                </TheNewsContainer>
                                <NewsImage src={news.imageUrl == null ? '/images/LSLogoEN.png' : news.imageUrl} alt="/images/LSLogoEN.png" isMobile={isMobile} />
                            </Link>
                        </NewsCard>
                        ) 
                }) : "..."}
            </Section>
        </>
    )
}

const Section = styled.div`
    margin-top:20px;
    margin-bottom:20px;
    text-align: center;
    column-count:3;
    @media screen and (max-width: 1024px){
        column-count:2;
    }
    @media screen and (max-width: 720px){
        column-count:1;
    }
`

const NewsCard = styled.div`
    width: ${props => props.isMobile ? '200px' : '325px'}
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    padding: 0px 12px 12px 12px;
    margin-bottom:13px;
    margin-left: auto;
    margin-right: auto;
    &:hover{
        box-shadow: 0 8px 16px 0 rgb(195,8,16);
        cursor: pointer;  
    }
    break-inside: avoid;
    break-inside: avoid-column;
`

const TheNewsContainer = styled.div`
    font-size:x-large
`

const NewsImage = styled.img`
    margin-left: auto;
    margin-right: auto;
    max-width: 100%
    max-height: 100%
`;

const mapStateToProps = ({ newsEntries }) => { return { newsEntries } }
const mapDispatchToProps = { loadNewsEntries };

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(NewsContainer));