import React from 'react';
import { LoginContainer } from '../../../smart';
import Logout from './Logout';
import { Helmet } from 'react-helmet-async';

const LogoutPage = () => {
  return (
    <LoginContainer>
      <Helmet>
        <title>
          User Logon & Logoff
        </title>
      </Helmet>
      <Logout />
    </LoginContainer>
  );
};

export default LogoutPage;
