import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { loadOrders } from '../../../redux/actions/orderActions';

export const OrdersContainer = ({
  activeLanguage,
  loadOrders,
  orders,
  history,
  apiCallsInProgress,
  totalElements,
  totalPages,
  ...props
}) => {
  useEffect(() => {
    const param = { language: activeLanguage?.code || 'en' };
    loadOrders(param, 0);
}, [activeLanguage, loadOrders]);
  
  const childrenWithProps = React.Children.map(props.children, child =>
      React.cloneElement(child, { apiCallsInProgress, orders, loadOrders, history, totalPages, totalElements })
  );

  return <>{childrenWithProps}</>;
};

OrdersContainer.propTypes = {
  loadOrders: propTypes.func.isRequired,
  orders: propTypes.array.isRequired,
};

function mapStateToProps({ orders, apiCallsInProgress }) {
  const { content, totalElements, pageable, totalPages } = orders;
  return {
    orders: content,
    totalElements,
    pageable,
    totalPages,
    apiCallsInProgress,
  };
}

const mapDispatchtoProps = {
  loadOrders,
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(OrdersContainer);
