import React, { useContext } from 'react';
import Intro from './Intro';
import RewardsMarketplace from './RewardsMarketplace';
import UncomplicatedThings from './UncomplicatedThings';
import RewardsCatalogue from './RewardsCatalogue';
import DistributionCenter from './DistributionCenter';
import Stats from './Stats';
import UIAside from './../../interface/UIAside';
import UIMain from './../../interface/UIMain';
import { sizing } from '../../../../defaults/styles';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { LoggedInHomeContainer } from '../../../smart/LoggedInHome';
import { HomePagePresentation } from './HomePagePresentation';
import { Helmet } from 'react-helmet-async';

const HomePage = ({history}) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);

  return (
    <>
      <Helmet>
        <title>Home - Loyalty Source</title>
        <meta
          name="description"
          content="Welcome to Loyalty Source, the ultimate destination for rewards and benefits. Explore our marketplace, catalogues, and more!"
        />
        <meta
          name="keywords"
          content="Loyalty Source, Rewards, Marketplace, Catalogue, Benefits, Distribution Center"
        />
        <meta name="author" content="Loyalty Source" />

      </Helmet>
      <UIAside
        noPadding
        customStyle={{
          paddingTop: isMobile || isTablet ? sizing.header.mobileTop + sizing.header.mobileBottom : sizing.header.top + sizing.header.mid + sizing.header.top,
        }}
      >
        <LoggedInHomeContainer>
          <Intro isMobile={isMobile} isTablet={isTablet} />
        </LoggedInHomeContainer>
      </UIAside>
      <UIMain noPadding isMobile={isMobile} isTablet={isTablet}>
        <RewardsMarketplace isMobile={isMobile} isTablet={isTablet} />
        <RewardsCatalogue isMobile={isMobile} isTablet={isTablet} />
        <LoggedInHomeContainer>
            <HomePagePresentation
              history={history}
              isMobile={isMobile}
              isTablet={isTablet}
            />
        </LoggedInHomeContainer>
        <UncomplicatedThings isMobile={isMobile} isTablet={isTablet} />
        <DistributionCenter isMobile={isMobile} isTablet={isTablet} />
        <Stats isMobile={isMobile} isTablet={isTablet} />
      </UIMain>
    </>
  );
};
export default HomePage;
