import { LSFetch } from "../helpers";

export const getBrands = (language = "en") => {
  return LSFetch.get("/brand", { params: { language } });
};

export const getBrandId = name => {
  return LSFetch.get("/brand/name", { params: { name } });
}

export const getBrandCount = () => {
  return LSFetch.get("/brand/count", {params: {}});
}