import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { brand } from '../../../../tools/api';
import CatalogPage from '../CatalogPage';
import { GridLoader } from 'react-spinners';
import { colors, fonts } from '../../../../defaults/styles';

const BrandRedirect = () => {
  const { lang, brandname } = useParams();
  const history = useHistory();

  const [brandId, setBrandId] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const getBrandId = async () => {
      try {
        const response = await brand.getBrandId(brandname);
        const fetchedBrandId = await response.json;

        if (isMounted) {
          if (fetchedBrandId) {
            setBrandId(fetchedBrandId);
          } else {
            setError(
              lang === 'fr' ?
                `Pas de résultat pour la marque ${brandname}` :
                `No result for brand ${brandname}`);
          }
        }
      } catch (error) {
        if (isMounted) {
          setError(
            lang === 'fr' ?
              'Erreur lors de la récupération des informations sur la marque' :
              'Error fetching brand information');
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    getBrandId();
    return () => {
      isMounted = false;
    };
  }, [brandname, lang]);

  if (loading) {
    return <GridLoader
      css={{ margin: 'auto' }}
      size={fonts.sizes.text}
      color={colors.main.normal}
    />;
  }

  // Render CatalogPage with brandId
  return (
    <CatalogPage
      specificBrandName={brandname}
      brandId={brandId}
      lang={lang}
      history={history}
      error={error}
    />
  );
};

export default BrandRedirect;