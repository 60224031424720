export const tFactory = uniqueLexiconPath => {
  return lexiconKey => {
    return `${uniqueLexiconPath}.${lexiconKey}`
  }
}

export const formatLexicon = (lexicon, uniqueLexiconPath) => {
  const formattedLexicon = Object.keys(lexicon).map(lexiconKey => {
    const newLexiconKey = `${uniqueLexiconPath}.${lexiconKey}`
    return { [newLexiconKey]: lexicon[lexiconKey] }
  })
  return Object.assign(...formattedLexicon)
}

export const setLexicon = (uniqueLexiconPath, incomingLexicon) => {
  const t = tFactory(uniqueLexiconPath)
  const lexicon = formatLexicon(incomingLexicon, uniqueLexiconPath)
  return { t, lexicon }
}

// Interpolation function for dynamic values
export const interpolateLexicon = (lexicon, fixedReplacements = []) => {
  const interpolate = (text) => {
    if (typeof text !== 'string') return text;

    // Replace fixed placeholders (e.g., ${count})
    fixedReplacements.forEach(([placeholder, replacement]) => {
      text = text.replace(new RegExp(`{{${placeholder}}}`, 'g'), replacement.toString());
    });

    return text;
  };

  const recurse = (obj) =>
    Object.entries(obj).reduce((acc, [key, value]) => {
      acc[key] = Array.isArray(value)
        ? value.map(interpolate)
        : typeof value === 'object'
        ? recurse(value)
        : interpolate(value);
      return acc;
    }, {});

  return recurse(lexicon);
};


// Main function to set lexicon with interpolation
export const resetLexicon = (uniqueLexiconPath, incomingLexicon, variables = {}) => {
  const interpolatedLexicon = interpolateLexicon(incomingLexicon, variables);

  const t = tFactory(uniqueLexiconPath);
  const lexicon = formatLexicon(interpolatedLexicon, uniqueLexiconPath);
  return { t, lexicon };
};
