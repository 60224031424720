import React, { useContext } from 'react';
import UIMain from './../../interface/UIMain';
import Intro from './Intro';
import Experts from './Experts';
import OurValues from './OurValues';
import OurPeople from './OurPeople';
import OurPromise from './OurPromise';
import OurHistory from './OurHistory';
import { withLocalize } from 'react-localize-redux';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { Helmet } from 'react-helmet-async';

const AboutUsPage = ({ translate }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);

  return (
    <>
      <Helmet>
        <title>{translate('aboutUs.page.title')}</title>
        <meta
          name="description"
          content={translate('aboutUs.intro.subTitle')}
        />
        <meta
          name="keywords"
          content="Loyalty Source, Rewards, Marketplace, Catalogue, Benefits, Distribution Center"
        />
        <meta name="author" content="Loyalty Source" />

      </Helmet>
      <UIMain isMobile={isMobile} isTablet={isTablet}>
        <Intro isMobile={isMobile} isTablet={isTablet} />
        <Experts isMobile={isMobile} isTablet={isTablet} />
        <OurValues isMobile={isMobile} isTablet={isTablet} />
        <OurPeople isMobile={isMobile} isTablet={isTablet} />
        <OurPromise isMobile={isMobile} isTablet={isTablet} />
        <OurHistory isMobile={isMobile} isTablet={isTablet} />
      </UIMain>
    </>
  );
};

export default withLocalize(AboutUsPage);
