import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleMenu } from '../../../../../../../../redux/actions/togglePopupActions';
import UserLoginButton from './UserLoginButton';
import UserProfileButton from './UserProfileButton';
import UserMenu from './UserMenu';
import PulseLoader from 'react-spinners/PulseLoader';
import { lsStyled as styled } from './../../../../../../../../tools/helpers/lsStyled';
import { colors, spacing } from '../../../../../../../../defaults/styles';

// == Styling == //
const Section = styled.section`
  position: relative;
`;

// == /Styling == //
const UserSection = ({ user }) => {
  // Access the menu state from Redux
  const userMenuOpen = useSelector((state) => state.togglePopups.showUserMenu);
  const dispatch = useDispatch();

  const handleMouseEnter = useCallback(() => {
    dispatch(toggleMenu(true));
  }, [dispatch]);

  const handleMouseLeave = useCallback(() => {
    dispatch(toggleMenu(false));
  }, [dispatch]);

  return (
    <Section
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {user ? (
        user.loggedIn ? (
          <>
            <UserProfileButton username={user.name} />
            {userMenuOpen && <UserMenu />}
          </>
        ) : (
          <UserLoginButton />
        )
      ) : (
        <div style={{ marginLeft: spacing.verySmall }}>
          <PulseLoader size={5} color={colors.gray.light} />
        </div>
      )}
    </Section>
  );
};

export default UserSection;
