import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '../../interface/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/pro-regular-svg-icons';
import { loadCart, } from '../../../../redux/actions/cartAction';
import { withLocalize } from 'react-localize-redux';
import ItemCounter from '../../interface/ItemCounter';

export const NavigateToCheckoutButton = ({
    loadCart,
    user,
    cart,
    activeLanguage,
    ...props
  }) => {
    const [ numberOfItemsInCart, setNumberOfItemsInCart ] = useState(0);

    useEffect(() => { 
        if (user && user.loggedIn && activeLanguage) {
            loadCart(activeLanguage.code, cart=>({...cart, 
                items: cart.items
                                .filter(({statusId})=> statusId !== 4), 
            }));
        }
    }, [ user, props.cart, activeLanguage ]);

    useEffect(() => {
      if(!cart || !cart.items){
          setNumberOfItemsInCart(0);
          return;
      }
      setNumberOfItemsInCart(cart.items
                                    .map(({quantity})=>quantity)
                                    .reduce((a, b) => a + b, 0));
    }, [ cart ])

    return (<>{user && user.loggedIn && (
                <Button url={`/checkout`} size={16} fill={"true"} isHeader={"true"} >
                    <FontAwesomeIcon icon={faShoppingCart}/>
                    { numberOfItemsInCart > 0 && (
                        <ItemCounter 
                            marginLeft={2.25}
                            count={numberOfItemsInCart}
                            size={1.25}
                            activeLanguage={activeLanguage}/>)}
                    </Button>
                )}</>);
};

function mapStateToProps({ cart, user }) { return { cart, user }; }
const mapDispatchToProps = { loadCart, };

export default withLocalize(connect(
    mapStateToProps,
    mapDispatchToProps,
) (NavigateToCheckoutButton));
