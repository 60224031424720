import React, { useState, useContext } from 'react';
import UIMain from './../../interface/UIMain';
import Intro from './Intro';
import NavigatorWrapper from './NavigatorWrapper';
import List from './List';
import { BrandsContainer } from '../../../smart';
import ScrollToTop from '../../../../main/ScrollToTop';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { withLocalize } from 'react-localize-redux';
import { Helmet } from 'react-helmet-async';

const BrandsPage = ({ history, translate }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);

  const t = (tag) => `brand.${tag}`;
  
  const getURL = window.location.href;
  const hash =
    getURL.substring(getURL.length - 2, getURL.length - 1) === '#'
      ? getURL.split('#').pop()
      : 'A';
  
  const [letterSelected, setLetterSelected] = useState(hash);
  return (
    <ScrollToTop>
      <Helmet>
        <title>{translate(t('pageTitle'))}</title>
        <meta
          name="description"
          content={translate(t('mainParagraph'))}
        />
        <meta
          name="keywords"
          content={translate(t('keywords'))}
        />
      </Helmet>
      <UIMain isMobile={isMobile} isTablet={isTablet}>
        <Intro isMobile={isMobile} />
        <BrandsContainer>
          <NavigatorWrapper
            isMobile={isMobile}
            letterSelected={letterSelected}
            setLetterSelected={setLetterSelected}
          />
          <List
            history={history}
            letterSelected={letterSelected}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        </BrandsContainer>
      </UIMain>
    </ScrollToTop>
  );
};

export default withLocalize(BrandsPage);
