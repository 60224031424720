import React, { useContext } from 'react';
import PageTitle from './../../interface/PageTitle';
import Intro from './Intro';
import WhitegloveService from './WhitegloveServiceSection';
import PlWarehousing from './PlWarehousingSection';
import CustomizableLabelling from './CustomizableLabellingSection';
import Catalogue from './CatalogueSection';
import { Translate, withLocalize } from 'react-localize-redux';
import { sizing } from './../../../../defaults/styles';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { Helmet } from 'react-helmet-async';

const ServicesPage = ({ translate }) => {
  const { isMobile } = useContext(ResizeAwareContext);

  return (
    <main style={{ marginBottom: sizing.paddingEq }}>
      <Helmet>
        <title>
          {translate('services.legacy.title')}
        </title>
        <meta
          name="description"
          content={translate('services.legacy.metaDescription')}
        />
        <meta
          name="keywords"
          content={translate('services.legacy.metaKeywords')}
        />
      </Helmet>
      <PageTitle text={<Translate id={translate('services.legacy.title')} />} />
      <Intro />
      <WhitegloveService />
      <PlWarehousing />
      <CustomizableLabelling />
      <Catalogue isMobile={isMobile} />
    </main>
  );
};

export default withLocalize(ServicesPage);
