import React from 'react';
import { render } from 'react-dom';
import App from './main/App';
import configureStore from './redux/store/configure';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { LocalizeProvider } from 'react-localize-redux';
import { StyleRoot } from 'radium';
import ScrollToTop from './main/ScrollToTop';
import { HelmetProvider } from 'react-helmet-async';
const store = configureStore();

render(
  <ReduxProvider store={store}>
    <LocalizeProvider>
      <HelmetProvider>
        <Router>
          <ScrollToTop>
            <StyleRoot>
              <App />
            </StyleRoot>
          </ScrollToTop>
        </Router>
      </HelmetProvider>
    </LocalizeProvider>
  </ReduxProvider>,
  document.getElementById('root')
);
