import React, { useContext } from 'react';
import TermsAndConditionsSection from './TermsAndConditionsSection';
import UIMain from '../../interface/UIMain';

import { withLocalize } from 'react-localize-redux';
import { sizing } from './../../../../defaults/styles';
import PageTitleSimple from '../../interface/PageTitleSimple';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { Helmet } from 'react-helmet-async';

const TermsAndConditionsPage = ({ translate }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);

  return (
    <main style={{ marginBottom: sizing.paddingEq }}>
      <UIMain isMobile={isMobile} isTablet={isTablet}>
        <Helmet>
          <title>
            {translate('termsAndConditionTitle.title')}
          </title>
          <meta
            name="description"
            content={translate('termsAndConditionTitle.metaDescription')}
          />
          <meta
            name="keywords"
            content={translate('termsAndConditionTitle.metaKeywords')}
          />
        </Helmet>
        <PageTitleSimple translateId={'termsAndConditionTitle.title'} isMobile={isMobile} isTablet={isTablet} />
        <TermsAndConditionsSection isMobile={isMobile} isTablet={isTablet} />
      </UIMain>
    </main>
  );
};

export default withLocalize(TermsAndConditionsPage);
