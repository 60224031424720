import React, { Fragment, useEffect, useRef, useState } from 'react';
import Navigator from './Navigator';
import UIContainer from './../../interface/UIContainer';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { colors, spacing, sizing } from '../../../../defaults/styles';

const Nav = styled.nav`
  background-color: ${colors.white.normal};
  display: block;
  position: sticky;
  top: 0;
  z-index: 100;

  height: ${props => (props.isSticky ? sizing.header.top + 'px' : 'auto')};
`;

const NavigatorWrapper = ({ brands, letterSelected, setLetterSelected, isMobile }) => {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const [activeNav, setActiveNav] = useState('#A'); // IDs for the alphabet indicator

  const handleScroll = () => {
    // sticky header
    if (!ref.current) return;
    
    setSticky(
      ref.current.getBoundingClientRect().top <=
        sizing.header.top + sizing.header.mid + sizing.header.btm
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <UIContainer
        isMobile={isMobile}
        noPadding
      >
        <Nav isSticky={isSticky} ref={ref}>
          <Navigator
            brands={brands.map(brand => brand[0])}
            letterSelected={letterSelected}
            setLetterSelected={setLetterSelected}
            isSticky={isSticky}
            setActiveNav={setActiveNav}
            activeNav={activeNav}
            isMobile={isMobile}
          />
        </Nav>
      </UIContainer>
    </Fragment>
  );
};

export default NavigatorWrapper;
