import React, { useContext } from 'react';
import UIMain from './../../interface/UIMain';
import { withLocalize } from 'react-localize-redux';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import Form from './Form';
import { Helmet } from 'react-helmet-async';

const ContactBriefPage = ({ translate }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <Helmet>
        <title>{translate('services.gifting.title')}</title>
        <meta
          name="description"
          content={translate('services.gifting.giftDescription')}
        />
        <meta
          name="keywords"
          content={translate('services.gifting.giftKeywords')}
        />
      </Helmet>
      <Form isMobile={isMobile} isTablet={isTablet}/>
    </UIMain>
  );
};

export default withLocalize(ContactBriefPage);
