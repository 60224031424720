import React, { useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { spacing, colors } from '../../../../../defaults/styles';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import { ShoppingListContainer } from '../../../../smart/ShoppingList';
import ShoppingListPopup from '../../../pages/CatalogPage/ShoppingList/ShoppingListPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { toggleShoppingList } from '../../../../../redux/actions/togglePopupActions';

const ManageShoppingLists = ({    
    isMobile,
    user,
}) => {
    const dispatch = useDispatch();
      const showPopup = useSelector((state) => state.togglePopups.showShoppingListPopup);

    const [isHovered, setIsHovered] = useState(false);
    const t = (tag) => `shoppingListContainer.${tag}`;
    const loggedIn = user && user.loggedIn;

    const handleShoppingListClick = (event) => {
        event.preventDefault();
        dispatch(toggleShoppingList(!showPopup));
    };
    return (
        <>
        {loggedIn && (
            <Outer 
                isMobile={isMobile}
                isHovered={isHovered}
                onMouseEnter={() => setIsHovered(true) }
                onMouseLeave={() => setIsHovered(false) }
            >
                <TitleWrapper onClick={handleShoppingListClick}>
                    <Translate id={t('sideBarTitle')} />
                    <FontAwesomeIcon
                        icon={faTasks}
                    />
                </TitleWrapper>
                <ShoppingListContainer>
                    <ShoppingListPopup
                        showPopup={showPopup}
                        setShowPopup={(isOpen) => dispatch(toggleShoppingList(isOpen))}
                        isMobile={isMobile}
                    />
                </ShoppingListContainer>
            </Outer>
        )}
        </>
    );
}

const Outer = styled.div`
    border-top: 1px solid ${colors.gray.light};
    padding-top: ${spacing.medium}px;
    ${props => props.isMobile ? 'padding-right: ' + spacing.verySmall + 'px' : ''};
    padding-left: ${spacing.verySmall}px;
    ${props => props.isMobile ? 'margin-bottom:' + spacing.large + 'px' : ''};
    margin-top: ${spacing.large}px;
`;

const TitleWrapper = styled.div`
    background-color: ${colors.gray.normal};
    color: ${colors.white.normal};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${spacing.small}px;
    padding: ${spacing.small}px;
    user-select: none;
    transition: all 250ms;

    :hover {
        background-color: ${colors.gray.light};
        color: ${colors.black.normal};

    }
`;

export default withRouter(withLocalize(ManageShoppingLists));