import React, { useContext, useState, useEffect } from 'react';
import { withLocalize } from "react-localize-redux";
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import UIMain from '../../interface/UIMain';
import UISection from '../../interface/UISection';
import { colors, fonts, sizing } from '../../../../defaults/styles';

import GridLoader from 'react-spinners/GridLoader';
import CartList from './CartList';
import CartSummary from './CartSummary';
import CartConfirm from './CartConfirm';
import CartSubmit from './CartSubmit';
import Breadcrumbs from './breadcrumbs/index';
import Disclaimer from './Disclaimer';
import ConfirmationEmail from './ConfirmationEmail';

// const cartWidth = sizing.cartWidth;

const sectionStyle = (isMobile) => isMobile
    ? {
        maxWidth: sizing.contentWidth,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        paddingRight: '1.5em',
        paddingLeft: '1.5em',
    }
    : {
        maxWidth: sizing.contentWidth,
        margin: '2.5em auto',
        display: 'grid',
        gridTemplateColumns: '5fr 4fr',
        gridGap: '2.5rem',
        position: 'relative',
    };
 
const CartContent = ({ 
    updateCartItem, 
    removeFromCart, 
    updateCartInfo, 
    updateCartAddress, 
    submitCart, 
    sendConfirmation, 
    history,
    ...props 
}) => {
    const { isMobile, isTablet } = useContext(ResizeAwareContext);
    const [ cart, setCart ] = useState(null);
    const [ confirm, setConfirm ] = useState(false);
    const [ submit, setSubmit ] = useState(false);
    const [ breadIndex, setBreadIndex ] = useState(0);

    useEffect(() => { setCart(props.cart); }, [props.cart]);
    return (
        <UIMain isMobile={isMobile} isTablet={isTablet}>
            <UISection customStyle={sectionStyle(isMobile)}>
                {cart && 
                    <>
                        {!submit
                            ? !confirm
                                ? 
                                    <div>
                                        <CartList 
                                            cart={cart} 
                                            updateCartItem={updateCartItem} 
                                            removeFromCart={removeFromCart} 
                                            isMobile={isMobile}
                                            history={history}
                                        />
                                        {/* Disclaimer only when cart is available and not yet submitted */}
                                        {/* { Object.entries(cart).length !== 0 && !isMobile && (<Disclaimer />) }  */}
                                    </div>
                                : 
                                    <div>
                                        <CartConfirm 
                                            cart={cart} 
                                            updateCartInfo={updateCartInfo} 
                                            updateCartAddress={updateCartAddress} 
                                            isMobile={isMobile}
                                        />
                                        {/* Disclaimer only when cart is available and not yet submitted */}
                                        {/* { Object.entries(cart).length !== 0 && !isMobile && (<Disclaimer />) }  */}
                                    </div>
                            : <CartSubmit cart={cart} isMobile={isMobile} />
                        }
                        <CartSummary 
                            cart={cart} 
                            confirm={confirm} 
                            submit={submit}
                            setCart={setCart} 
                            setConfirm={setConfirm} 
                            setSubmit={setSubmit} 
                            submitCart={submitCart}
                            breadIndex={breadIndex}
                            setBreadIndex={setBreadIndex}
                            isMobile={isMobile}
                        />
                    </>
                }

                <ConfirmationEmail cart={cart} submit={submit} sendConfirmation={sendConfirmation} />

                {/* Disclaimer only when cart is available and not yet submitted */}
                { cart && !submit && isMobile && (<Disclaimer />) }    
            
                <Breadcrumbs 
                    breadIndex={breadIndex} 
                    setBreadIndex={setBreadIndex}
                    cart={cart} 
                    setCart={setCart}
                    confirm={confirm}
                    setConfirm={setConfirm} 
                    submit={submit}
                    setSubmit={setSubmit} 
                    submitCart={submitCart}
                    isMobile={isMobile} 
                />
            </UISection>

            {/* Show loading when cart is being loaded */}
            {!cart && (
                    <UISection>
                        <GridLoader
                            css={{ margin: 'auto' }}
                            size={fonts.sizes.text}
                            color={colors.main.normal} 
                        />
                    </UISection>
            )}
        </UIMain>
    );
}

export default withLocalize(CartContent);