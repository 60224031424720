import React, { useContext } from 'react';
import UIMain from './../../interface/UIMain';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import Intro from './Intro';
import ContactInfoSection from './ContactInfoSection';
import RequestForm from './RequestForm';
import { spacing } from '../../../../defaults/styles';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { withLocalize } from 'react-localize-redux';
import { Helmet } from 'react-helmet-async';

const AccountRequestPage = ({ translate }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <Helmet>
        <title>{translate('accountRequest.intro.title')}</title>
        <meta
          name="description"
          content={translate('accountRequest.intro.description')}
        />
        <meta
          name="keywords"
          content={translate('accountRequest.intro.keywords')}
        />
        <meta name="author" content="Loyalty Source" />
      </Helmet>
      <Intro isMobile={isMobile} isTablet={isTablet} />
      <UIContainer
        isMobile={isMobile}
        isTablet={isTablet}
        noPadding
        customStyle={{ marginBottom: spacing.veryLarge }}
      >
        <UISection
          isMobile={isMobile}
          isTablet={isTablet}
          alignItems={'start'}
          customStyle={{ gridGap: '2em' }}
        >
          <RequestForm isMobile={isMobile} />
        </UISection>
      </UIContainer>
      <UIContainer
        isMobile={isMobile}
        isTablet={isTablet} 
        customStyle={{ marginBottom: '2em' }}
      >
        <UISection
          isMobile={isMobile}
          isTablet={isTablet}
          noPadding
        >
          <ContactInfoSection 
            isMobile={isMobile}
            isTablet={isTablet} 
          />
        </UISection>
      </UIContainer>
    </UIMain>
  );
};

export default withLocalize(AccountRequestPage);