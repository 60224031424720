import React, { useEffect, useState, useContext } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadBlogPosts } from '../../../redux/actions/blogActions';
import { lsStyled as styled } from './../../../tools/helpers/lsStyled';
import { colors } from '../../../defaults/styles';
import moment from 'moment';
import { ResizeAwareContext } from '../../smart/ResizeContext';
import { GridLoader } from 'react-spinners';
import { Helmet } from 'react-helmet-async';

const linkStyle = {
    textDecoration: "none",
    color: 'black'
}
const headerStyle = {
    marginTop: "12px",
}

const buttonContainer ={
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
}

const publicationDateStyle ={
    alignSelf: 'flex-end',
    textAlign: 'center',
}

export const BlogsContainer = ({ blogPosts, loadBlogPosts, translate, activeLanguage, ...props }) => {

    useEffect(() => {window.scrollTo(0, 0);})
    const { isMobile, isTablet } = useContext(ResizeAwareContext);
    //pageable component
    const [blogPage, setBlogPage] = useState(0);
    useEffect(() => {
        if (activeLanguage){
            loadBlogPosts(blogPage, activeLanguage.code);
        }
    }, [activeLanguage, blogPage, loadBlogPosts])

    const pages = blogPosts.blogs != null ? blogPosts.blogs.totalPages :0;
    return (
        <>
            <Helmet>
                <title>{translate('blogContainer.blogTitle')}</title>
                <meta
                    name="description"
                    content={translate('blogContainer.slogan')}
                />
                <meta
                    name="keywords"
                    content={translate('blogContainer.slogan')}
                />
                <meta name="author" content="Loyalty Source" />
            </Helmet>
            <Section isMobile={isMobile} isTablet={isTablet}>
                <>
                    {blogPosts.blogs != null && blogPosts.blogs.content != null && blogPosts.blogs.content.length > 0 ? (
                        <>
                            {blogPosts.blogs.content.map(blog => {
                                return (
                                    <BlogCard isMobile={isMobile} isTablet={isTablet} key={blog.id}>
                                        <Link to={`/${activeLanguage.code}/blog/${encodeURIComponent(blog.blogTitle).replace(/%20/g, '-')}`} style={linkStyle}>
                                            <BlogImage src={blog.imageUrl == null ? '/images/LSLogoEN.png' : blog.imageUrl} alt="/images/LSLogoEN.png" isMobile={isMobile}></BlogImage>
                                            <BlogContainer>
                                                <h1 style={headerStyle}>{blog.blogTitle}</h1>
                                                <h3 style={headerStyle}>{blog.blogHeadline}</h3>
                                            </BlogContainer>
                                        </Link>
                                        <p style={publicationDateStyle}>
                                            {translate("blogContainer.publication")} {activeLanguage.code === 'en' ?
                                                moment(blog.startDate).lang("en").format('dddd, MMMM Do YYYY') :
                                                moment(blog.startDate).lang("fr").format('D MMMM YYYY')}
                                        </p>
                                    </BlogCard>
                                );
                            })}
                        </>
                    ) : (
                        <LoaderContainer>
                            <GridLoader
                                size={12}
                                color={colors.main.normal} />
                        </LoaderContainer>
                    )}
                </>
            </Section>
            <div style={buttonContainer}>
                {blogPage === 0 && pages > 1 && (<Button onClick={() => setBlogPage(blogPage + 1)}>{translate('blogContainer.nextPage')}</Button>)}
                {blogPage > 0 && blogPage < (pages - 1) && (
                    <>
                        <Button onClick={() => setBlogPage(blogPage - 1)}>{translate('blogContainer.previousPage')}</Button>
                        <Button onClick={() => setBlogPage(blogPage + 1)}>{translate('blogContainer.nextPage')}</Button>
                    </>
                )}
                {blogPage > 0 && blogPage === (pages - 1) && (<Button onClick={() => setBlogPage(blogPage - 1)}>{translate('blogContainer.previousPage')}</Button>)}
            </div>
        </>
    )
}

const Section = styled.div`
margin-top:20px;
margin-bottom:20px;
text-align: center;
display: grid;
grid-template-columns: ${props => (props.isTablet || props.isMobile) ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'};
grid-auto-rows: auto;
grid-gap: 1rem;
}
`

const BlogCard = styled.div`
display: grid;
width: ${props => props.isMobile ? '400px' : '495px'}
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
transition: 0.3s;
border-radius: 5px;
padding: 12px;
margin-bottom:5px;
margin-left: auto;
margin-right: auto;
&:hover{
    box-shadow: 0 8px 16px 0 rgb(195,8,16);
    cursor: pointer;  
}
`

const BlogContainer = styled.div`
'align-self': 'flex-start',
`

const BlogImage = styled.img`
display: block;
margin-left: auto;
margin-right: auto;
max-width: 100%
max-height: 100%
`;

const Button = styled.button`
    margin: 30px;
    background-color: ${props => props.primary ? colors.primary.normal : "white"};
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    border-color: ${props => props.primary ? colors.primary.normal : 'rgba(149, 149, 149, 0.5)'};
    color: ${props => props.primary ? 'white' : colors.gray.normal};
    cursor: pointer;
    padding: 10px 15px;
    font-size: 28px;
    font-weight: 400;
    &:hover{
        box-shadow: 0 0 16px 1px rgb(195,8,16);
        cursor: pointer;
    }
`;

const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 100%;
    margin-left: 20vh
`;

const mapStateToProps = ({ blogPosts }) => { return { blogPosts } }
const mapDispatchToProps = { loadBlogPosts };

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(BlogsContainer));