import * as types from '../actions/types'
import { brandCount } from './initialState'

const brandCountReducer = (state = brandCount, action) => {
  switch (action.type) {
    case types.LOAD_BRAND_COUNT_SUCCESS:
      return action.brandCount
    default:
      return state
  }
}

export default brandCountReducer;